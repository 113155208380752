import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
const BotForm = () => {
    return (
        <>
            <SEO title="BOT Acceptance" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">BOT Acceptance</h3>
                <Pageheader />
                <iframe title="bot" src="https://docs.google.com/forms/d/e/1FAIpQLSd8E43gZxT9EK_wlQdqYwP93tCnvi6aY4pupu9xKoS0ItjfYg/viewform?embedded=true" width="100%" style={{ minHeight: "1200px" }} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </MDBContainer>
        </>
    );
};

export default BotForm;